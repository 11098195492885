<template>
	<div v-if="formDetail || orderDetail">
		<CCard class="mb-0">
			<CCardBody class="order__form">
				<div v-if="isView" class="shop__header">
					<h5 class="mb-3">{{ getTitle }}</h5>
					<h5 class="mb-3"> 1. {{ $t("OrdersTitle.Request") }} </h5>
				</div>
				<div v-else-if="isUpdate" class="shop__header">
					<h5 class="mb-3">{{ getTitleUpdate }}</h5>
					<h5 class="mb-3"> 1. {{ $t("OrdersTitle.Request") }} </h5>
				</div>
				<div v-else class="order__header">
					<h5 v-if="!$route.query.update_order" class="mb-3">
						{{ $t("OrdersTitle.NewOrder") }}
					</h5>
					<h5 v-else class="mb-3">
						{{ $t("OrdersTitle.NewUpdate") }}
					</h5>
				</div>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitForm)">
						<div class="custom">
							<div class="custom__input">
								<CRow>
									<!-- Update ID  -->
									<CCol v-if="isView || isUpdate" class="col-12 col-sm-6">
										<div class="form-group position-relative">
											<ValidationProvider mode="lazy">
												<div class="form-group row">
													<label for="update_id" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.update_id")
													}}</label>
													<div :class="['col-md-8 col-12']">
														<input
															id="update_id"
															v-model="orderForm.updateID"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.update_id')"
															autocomplete="update_id"
															disabled
														/>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>

									<!-- create date -->
									<CCol class="col-12 col-sm-6">
										<div class="form-group position-relative">
											<ValidationProvider>
												<div class="form-group row">
													<label
														for="create_date"
														class="col-sm-4 col-12 col-form-label"
														:class="{ 'text-right': isView || isUpdate }"
														>{{ $t("OrderFields.create_date") }}</label
													>
													<div :class="['col-12 col-sm-8 position-relative']">
														<date-picker
															id="create_date"
															v-model="createdDate"
															value-type="format"
															class="w-100"
															disabled
															:format="formatDate"
															:placeholder="$t('FormsPlacehoder.date_format')"
														/>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>

									<!-- form id -->
									<CCol class="col-12 col-sm-6">
										<div class="form-group position-relative">
											<ValidationProvider>
												<div class="form-group row">
													<label
														for="form_id"
														class="col-sm-4 col-12 col-form-label"
														:class="{ 'text-right': !isView && !isUpdate }"
														>{{ $t("OrderFields.form_id") }}</label
													>
													<div :class="['col-sm-8 col-12']">
														<input
															id="form_id"
															v-model.trim="orderForm.formID"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.form_id')"
															autocomplete="username"
															disabled
														/>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>

									<!-- status -->
									<CCol class="col-12 col-sm-6">
										<div class="form-group position-relative">
											<ValidationProvider ref="province" name="order_status">
												<div class="form-group row">
													<label
														for="status"
														class="col-sm-4 col-12 col-form-label"
														:class="{ 'text-right': isView || isUpdate }"
														>{{ $t("OrderFields.status") }}</label
													>
													<div :class="['col-sm-8 col-12']">
														<multiselect
															id="status"
															v-model="orderForm.status"
															:options="listStatus"
															:close-on-select="true"
															:show-labels="false"
															:allow-empty="false"
															:searchable="false"
															:placeholder="$t('OrderFields.status')"
															:disabled="!isUpdateOrder"
															label="name"
															track-by="value"
															class="custom_multiselect"
														>
															<template #noOptions>
																{{ $t("DropDown.NoOptions") }}
															</template>
															<template #noResult>
																{{ $t("DropDown.NoResult") }}
															</template>
														</multiselect>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>

									<!-- received date -->
									<CCol class="col-12 col-sm-6">
										<div class="form-group position-relative">
											<ValidationProvider name="form_order_id">
												<div class="form-group row">
													<label
														for="received_date"
														class="col-sm-4 col-12 col-form-label"
														:class="{ 'text-right': !isView && !isUpdate }"
														>{{ $t("OrderFields.received_date") }}</label
													>
													<div :class="['col-sm-8 col-12']">
														<date-picker
															id="received_date"
															v-model="orderForm.receivedDate"
															value-type="format"
															class="w-100"
															disabled
															:format="formatDate"
															:placeholder="$t('FormsPlacehoder.date_format')"
														/>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>

									<!-- email -->
									<CCol class="col-12 col-sm-6">
										<div class="form-group position-relative">
											<ValidationProvider ref="email" name="order_email">
												<div class="form-group row">
													<label
														for="email"
														class="col-sm-4 col-12 col-form-label"
														:class="{ 'text-right': isView || isUpdate }"
														>{{ $t("OrderFields.email") }}</label
													>
													<div :class="['col-sm-8 col-12']">
														<input
															id="email"
															v-model.trim="orderForm.customerEmail"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.email')"
															disabled
														/>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>

									<!-- phone number -->
									<CCol class="col-12 col-sm-6">
										<div class="form-group position-relative">
											<ValidationProvider ref="email" name="order_email">
												<div class="form-group row">
													<label
														for="phone"
														class="col-sm-4 col-12 col-form-label"
														:class="{ 'text-right': !isView && !isUpdate }"
														>{{ $t("OrderFields.phone") }}</label
													>
													<div :class="['col-sm-8 col-12']">
														<input
															id="phone"
															v-model.trim="orderForm.phoneNumber"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.phone')"
															disabled
														/>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>

									<!-- customer name -->
									<CCol class="col-12 col-sm-6">
										<div class="form-group position-relative">
											<ValidationProvider name="order_customer_name">
												<div class="form-group row">
													<label
														for="customer_name"
														class="col-sm-4 col-12 col-form-label"
														:class="{ 'text-right': isView || isUpdate }"
														>{{ $t("OrderFields.customer_name") }}</label
													>
													<div :class="['col-sm-8 col-12']">
														<input
															id="customer_name"
															v-model="orderForm.customerName"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.customer_name')"
															disabled
														/>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>

									<!-- customer id -->
									<CCol class="col-12 col-sm-6">
										<div class="form-group position-relative">
											<ValidationProvider name="order_customer_ID">
												<div class="form-group row">
													<label
														for="customer_id"
														class="col-sm-4 col-12 col-form-label"
														:class="{ 'text-right': !isView && !isUpdate }"
														>{{ $t("OrderFields.customer_id") }}</label
													>
													<div :class="['col-sm-8 col-12']">
														<input
															id="customer_id"
															v-model="orderForm.customerId"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.customer_id')"
															disabled
														/>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
								</CRow>

								<!-- title  -->
								<div class="form-group position-relative">
									<ValidationProvider name="order_title" mode="lazy">
										<div class="form-group row">
											<label for="title" class="col-sm-2 col-12 col-form-label">{{
												$t("OrderFields.title")
											}}</label>
											<div :class="['col-sm-10 col-12']">
												<input
													id="title"
													v-model.trim="orderForm.title"
													type="text"
													class="form-control"
													:placeholder="$t('OrderFields.title')"
													disabled
												/>
												<div class="error-mess"> </div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- description  -->
								<div class="form-group position-relative">
									<ValidationProvider name="order_description" mode="lazy">
										<div class="form-group row">
											<label for="description" class="col-sm-2 col-12 col-form-label">{{
												$t("OrderFields.description")
											}}</label>
											<div :class="['col-sm-10 col-12']">
												<textarea
													id="description"
													v-model.trim="orderForm.description"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('OrderFields.description')"
													autocomplete="description"
													disabled
												/>
												<div class="error-mess"> </div>
												<div class="text-right">
													<button
														class="px-4 btn btn-outline-custom"
														type="button"
														@click="translateText"
													>
														{{ $t("Button.Translate") }}
													</button>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- description translate -->
								<div class="form-group position-relative">
									<ValidationProvider name="order_description_translate" mode="lazy">
										<div class="form-group row">
											<label for="description_translate" class="col-sm-2 col-12 col-form-label">{{
												$t("OrderFields.descriptionTranslate")
											}}</label>
											<div :class="['col-sm-10 col-12']">
												<textarea
													id="description_translate"
													v-model.trim="orderForm.translatedDescription"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('OrderFields.descriptionTranslate')"
													autocomplete="description_translate"
													:disabled="isView"
												/>
												<div class="error-mess"> </div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- type  -->
								<div class="form-group position-relative">
									<ValidationProvider name="order_type">
										<div class="form-group row">
											<label for="type" class="col-sm-2 col-12 col-form-label">{{
												$t("OrderFields.type")
											}}</label>
											<div :class="['col-sm-10 col-12']">
												<div class="form__input d-flex align-items-center border rounded-sm">
													{{ orderForm.orderType }}
												</div>
												<div class="error-mess"> </div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- amount -->
								<div class="form-group position-relative">
									<ValidationProvider name="order_amount">
										<div class="form-group row">
											<label for="amount" class="col-sm-2 col-12 col-form-label">{{
												$t("OrderFields.amount")
											}}</label>
											<div :class="['col-sm-10 col-12']">
												<TableAmount
													:order-types="orderTypeSelected"
													:new-update-has-status-revision="isRevision"
													:disabled="true"
												/>
												<div class="error-mess"> </div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- system price -->
								<div class="form-group position-relative">
									<ValidationProvider name="order_system_price">
										<div class="form-group row">
											<label for="amount" class="col-sm-2 col-12 col-form-label">{{
												$t("OrderFields.systemPrice")
											}}</label>
											<div :class="['col-sm-10 col-12']">
												<div class="system-price h-100 d-flex align-items-center">{{
													orderForm.cost
												}}</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- actual price -->
								<div class="form-group position-relative">
									<ValidationProvider name="order_system_price">
										<div class="form-group row">
											<label for="amount" class="col-sm-2 col-12 col-form-label">{{
												$t("OrderFields.actualPrice")
											}}</label>
											<div :class="['col-sm-10 col-12']">
												<div class="system-price h-100 d-flex align-items-center">{{
													orderForm.cost
												}}</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<CRow>
									<CCol class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- time to do -->
										<div class="form-group position-relative">
											<ValidationProvider ref="time" name="order_time">
												<div class="form-group row">
													<label for="time_to_do" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.time_to_do")
													}}</label>
													<div :class="['col-md-8 col-12']">
														<input
															id="time_to_do"
															v-model.trim="orderForm.time"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.time_to_do')"
															autocomplete="time_to_do"
															disabled
														/>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- deadline -->
										<div class="form-group position-relative">
											<ValidationProvider ref="deadline" name="order_deadline">
												<div class="form-group row">
													<label for="deadline" class="col-md-4 col-12 col-form-label text-right">{{
														$t("OrderFields.deadline")
													}}</label>
													<div :class="['col-md-8 col-12']">
														<date-picker
															id="deadline"
															v-model="orderForm.deadLine"
															value-type="format"
															type="datetime"
															class="w-100"
															disabled
															:format="formatDate"
															:editable="false"
															:placeholder="$t('FormsPlacehoder.date_format')"
														/>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
								</CRow>

								<!-- rule  -->
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="order_rule" mode="lazy">
										<div class="form-group row">
											<label for="rule" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.rule")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<textarea
													id="rule"
													v-model.trim="orderForm.rule"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('OrderFields.rule')"
													autocomplete="rule"
													:maxlength="ORDER_RULE.RULE"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<UploadFiles
									:name="TYPE_UPLOAD.REQUEST"
									:show-delete="false"
									:show-download="true"
									:show-add="false"
									:is-required="false"
									:files="uploadFiles"
								/>

								<!-- admin link -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="adminLink"
										name="adminLink"
										mode="lazy"
										:rules="`isUrl`"
									>
										<div class="form-group row">
											<label for="adminLink" class="col-md-2 col-12 col-form-label">{{
												$t("FormsField.adminLink")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<div class="position-relative">
													<input
														id="adminLink"
														v-model.trim="orderForm.adminLink"
														type="text"
														class="form-control pr-5"
														:placeholder="$t('FormsField.adminLink')"
														:disabled="isUpdate || isView"
													/>
													<button
														v-if="!isUpdate"
														class="px-2 btn btn-outline-custom icon"
														type="button"
														:disabled="!orderForm.adminLink"
														@click="addLink"
													>
														<font-awesome-icon :icon="['fas', 'plus']" :title="$t('Action.Add')" />
													</button>
												</div>
												<div v-if="errors.length" class="error-mess">
													{{ errors[0] }}
												</div>
												<div v-if="hasAdminLink" class="dropbox-list py-3">
													<div
														v-for="(adminLink, index1) in orderForm.adminDropboxFolderLinks"
														:key="index1"
														class="d-flex justify-content-between align-items-center dropbox-item mb-1"
														:class="{ 'disabled-link': isView }"
													>
														<a :href="adminLink" target="_blank" class="dropbox-url">{{
															adminLink
														}}</a>
														<button
															class="px-2 btn btn-outline-danger btn-delete"
															type="button"
															:disabled="isUpdate"
															@click="removeLink(index1)"
														>
															<font-awesome-icon
																:icon="['fas', 'trash-alt']"
																:title="$t('Action.Delete')"
															/>
														</button>
													</div>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- link dropbox -->
								<div v-if="hasDropboxLink" class="form-group position-relative">
									<ValidationProvider ref="dropbox" name="order_dropbox" mode="lazy">
										<div class="form-group row">
											<label for="order_dropbox" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.linkDropbox")
											}}</label>
											<div :class="['col-md-10 col-12']">
												<div class="py-3 dropbox-list">
													<div
														v-for="(dropboxLink, index1) in orderForm.dropboxSharedFolderLinks"
														:key="index1"
														class="d-flex justify-content-between align-items-center dropbox-item mb-2 disabled-link"
													>
														<a :href="dropboxLink" target="_blank" class="dropbox-url">{{
															dropboxLink
														}}</a>
													</div>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- link drive -->
								<div v-if="hasDriveLink" class="form-group position-relative">
									<ValidationProvider ref="drive" name="order_drive" mode="lazy">
										<div class="form-group row">
											<label for="order_drive" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.linkDrive")
											}}</label>
											<div :class="['col-md-10 col-12']">
												<div
													v-if="
														orderForm.driveSharedFolderLinks &&
														orderForm.driveSharedFolderLinks.length
													"
													class="py-3 dropbox-list"
												>
													<div
														v-for="(driveLink, index2) in orderForm.driveSharedFolderLinks"
														:key="index2"
														class="d-flex justify-content-between align-items-center dropbox-item mb-2 disabled-link"
													>
														<a :href="driveLink" target="_blank" class="dropbox-url">{{
															driveLink
														}}</a>
													</div>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<template v-if="isView || isUpdate">
									<div class="shop__header">
										<h5 class="mb-3"> 2. {{ $t("OrdersTitle.Result") }} </h5>
									</div>

									<UploadFiles
										:name="TYPE_UPLOAD.RESULT"
										:show-delete="isUpdate"
										:show-download="true"
										:show-add="isUpdate"
										:files="resultFiles"
										:is-required="false"
										:show-checkbox="isCompleteUpdate"
										:label="$t('OrderFields.file_result')"
										:is-update="isUpdate"
										:source="SOURCES.WEB"
										@handleUploadFiles="handleUploadResultFiles"
										@handleDeleteFile="handleDeleteResultFile"
										@total-file="getTotalFiles"
										@choose-thumbnail="chooseThumbnail"
									/>

									<template v-if="isUpdate">
										<!-- switch complete -->
										<div class="form-group position-relative">
											<ValidationProvider name="order_complete_update" mode="lazy">
												<div class="form-group row">
													<label for="complete_update" class="col-md-2 col-sm-4 col-form-label">{{
														$t("OrderFields.complete_update")
													}}</label>
													<div :class="['col-md-10 col-sm-8 d-flex align-items-center']">
														<b-form-checkbox
															v-model="isCompleteUpdate"
															name="switch"
															switch
															class="switch"
															@change="changeIsCompleteUpdate"
														/>
													</div>
												</div>
											</ValidationProvider>
										</div>
										<!-- checkRequireThumbnail -->
										<div v-if="isCompleteUpdate">
											<ValidationProvider
												ref="thumbnail"
												v-slot="{ errors }"
												name="order_thumbnail_selected"
												:rules="{ requiredExport: checkRequireThumbnail }"
												mode="lazy"
											>
												<div class="form-group row">
													<label for="complete_update" class="col-md-2 col-sm-4 col-form-label">{{
														$t("OrderFields.thumbnail_selected")
													}}</label>
													<div :class="['col-md-10 col-sm-8']">
														<div class="thumbnail">
															<b-img-lazy
																v-if="thumbnailSelected"
																:src="thumbnailSelected.resizedSmallUrl"
																alt=""
																blank-color="#f3f3f3"
																blank
															/>
															<div v-else class="thumbnail-blank"></div>
														</div>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</template>

									<template v-if="isOrderComplete">
										<div v-if="orderForm.thumbnailResult" class="form-group position-relative">
											<ValidationProvider name="order_drive" mode="lazy">
												<div class="form-group row">
													<label for="order_drive" class="col-sm-2 col-12 col-form-label">{{
														$t("OrderFields.thumbnail")
													}}</label>
													<div :class="['col-sm-10 col-12']">
														<div class="thumbnail">
															<b-img-lazy
																:src="orderForm.thumbnailResult"
																alt=""
																blank-color="#f3f3f3"
																blank
															/>
														</div>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>
										<div v-if="orderForm.rate" class="form-group position-relative">
											<ValidationProvider name="order_drive" mode="lazy">
												<div class="form-group row">
													<label for="order_drive" class="col-sm-2 col-12 col-form-label">{{
														$t("OrderFields.rate")
													}}</label>
													<div :class="['col-sm-3 col-12']">
														<b-form-rating
															v-model="orderForm.rate"
															variant="warning"
															class="mb-2"
															size="lg"
															inline
															no-border
														></b-form-rating>
														<div class="error-mess"> </div>
													</div>
												</div>
											</ValidationProvider>
										</div>

										<div v-if="orderForm.comment" class="form-group position-relative">
											<ValidationProvider name="order_drive" mode="lazy">
												<div class="form-group row">
													<label for="order_drive" class="col-sm-2 col-12 col-form-label">{{
														$t("OrderFields.comment")
													}}</label>
													<div class="col-sm-10 col-12 d-flex align-items-center">{{
														orderForm.comment
													}}</div>
												</div>
											</ValidationProvider>
										</div>
									</template>
								</template>

								<!-- checkbox upload to sever -->
								<div v-if="!isView" class="form-group position-relative">
									<ValidationProvider name="order_upload_server" mode="lazy">
										<div class="form-group row">
											<label for="uploadToServer" class="col-md-2 col-sm-4 col-form-label">{{
												$t("OrderFields.uploadToServer")
											}}</label>
											<div :class="['col-md-10 col-sm-8 d-flex align-items-center']">
												<b-form-checkbox
													v-model="orderForm.shareFiles"
													name="checkbox-upload"
													class="checkbox"
												/>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>
						<CRow class="text-right">
							<CCol>
								<template v-if="!isView">
									<button v-if="!isUpdate" class="px-4 ml-2 btn btn-outline-custom" type="submit">
										{{ $t("Button.Create") }}
									</button>
									<button v-if="isUpdate" class="px-4 ml-2 btn btn-outline-custom" type="submit">
										{{ $t("Button.Update") }}
									</button>
									<button class="px-4 ml-2 btn btn-outline-danger" type="button" @click="back">
										{{ $t("Button.Back") }}
									</button>
								</template>
								<template v-if="isView">
									<button
										v-if="isSowButtonUpdate"
										class="px-4 ml-2 btn btn-outline-custom"
										type="button"
										@click="updateOreder"
									>
										{{ $t("Button.Update") }}
									</button>

									<button
										v-if="isShowCancelButton"
										type="button"
										class="ml-2 btn btn-outline-danger"
										@click="showModalCancelOrder"
									>
										{{ $t("Button.Cancel") }}
									</button>
								</template>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
		<CustomModal
			v-if="isCancelOrder"
			:submit-text="$t('Button.Confirm')"
			@submit-modal="submitModal"
			@cancel-modal="cancelModal"
		>
			<template #content>
				<ValidationObserver ref="formCancel">
					<form>
						<div class="custom">
							<div class="custom__input">
								<div class="form-group position-relative mb-0">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="order_reason"
										mode="lazy"
										:rules="`max:${CANCEL_REASON}`"
									>
										<div class="form-group row">
											<label for="reason" class="col-12 col-form-label mb-3">{{
												$t("OrderMessage.cancel_order")
											}}</label>
											<div :class="['col-12', classes]">
												<textarea
													id="description"
													v-model.trim="reason"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('FormsField.reason')"
													autocomplete="description"
													:maxlength="CANCEL_REASON"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>
					</form>
				</ValidationObserver>
			</template>
		</CustomModal>
		<CustomModal
			v-if="isDuplicateFile"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirmDuplicate"
			@cancel-modal="confirmDuplicate"
		>
			<template #content>
				{{ messageDuplicate }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapState: orderTypeState } = createNamespacedHelpers("typeOrder")
const { mapState: formState } = createNamespacedHelpers("forms")
const { mapActions: customerActions } = createNamespacedHelpers("customers")
const { mapActions } = createNamespacedHelpers("orders")
import moment from "moment"
import {
	DATE_FORMAT,
	ORDER_STATUS,
	ORDER_RULE,
	IGNORE_ORDER_STATUS,
	CANCEL_REASON,
	TYPE_UPLOAD,
	SOURCES,
} from "../../../shared/plugins/constants"
import { SEARCH_EMAIL } from "../../customers/store/action-types"
import { TRANSLATE_TEXT, CANCEL_ORDER_WEB } from "../store/action-types"
import { isInteger, round, join, uniqBy } from "lodash-es"
export default {
	name: "OrderFormWeb",
	components: {
		TableAmount: () => import("../components/TableAmount"),
	},
	props: {
		isRedirectForm: {
			type: Boolean,
			default: false,
		},
		formId: {
			type: Number,
			default: null,
		},
		orderDetail: {
			type: Object,
			default: () => {},
		},
		addAnUpdate: {
			type: Boolean,
			default: false,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
		isView: {
			type: Boolean,
			default: false,
		},
		isUpdateOrder: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			createdDate: moment(new Date()).format("DD-MM-YYYY HH:mm"),
			formatDate: DATE_FORMAT,
			ORDER_RULE,
			SOURCES,
			CANCEL_REASON,
			TYPE_UPLOAD,
			orderTypeSelected: [],
			isCancelOrder: false,
			reason: null,
			isUploadSuccess: false,
			totalFiles: null,
			isCompleteUpdate: false,
			isDuplicateFile: false,
			orderForm: {
				formID: null,
				status: null,
				receivedDate: null,
				customerEmail: null,
				phoneNumber: null,
				customerName: null,
				customerId: null,
				title: null,
				description: null,
				translatedDescription: null,
				orderType: null,
				cost: null,
				time: null,
				deadLine: null,
				rule: null,
				dropboxSharedFolderLinks: [],
				driveSharedFolderLinks: [],
				shareFiles: true,
				adminDropboxFolderLinks: [],
				adminLink: null,
				updateID: null,
				rate: 0,
				comment: null,
				isComplete: 1,
			},
			thumbnailSelected: null,
			uploadFiles: [],
			resultFiles: [],
			listStatus: [
				{
					name: this.$t("OrderFilterName.new_job"),
					value: ORDER_STATUS.NEW_JOB,
				},
				{
					name: this.$t("OrderFilterName.revision"),
					value: ORDER_STATUS.REVISION,
				},
				{
					name: this.$t("OrderFilterName.more_new_job"),
					value: ORDER_STATUS.MORE_NEW_JOB,
				},
				{
					name: this.$t("OrderFilterName.cancelled"),
					value: ORDER_STATUS.CANCELLED,
				},
				{
					name: this.$t("OrderFilterName.complete"),
					value: ORDER_STATUS.COMPLETED,
				},
			],
		}
	},
	computed: {
		...orderTypeState(["typesOrder"]),
		...formState(["formDetail"]),
		hasDropboxLink() {
			if (this.orderForm.dropboxSharedFolderLinks.length) {
				return true
			}
			return false
		},
		hasDriveLink() {
			if (this.orderForm.driveSharedFolderLinks.length) {
				return true
			}
			return false
		},
		hasAdminLink() {
			if (this.orderForm.adminDropboxFolderLinks.length) {
				return true
			}
			return false
		},
		getTitle() {
			return `${this.id} ${this.$t("OrdersTitle.Detail")}`
		},
		getTitleUpdate() {
			return `${this.$t("OrdersTitle.Order")} ${this.id}`
		},
		isOrderComplete() {
			if (this.orderForm.status?.value === ORDER_STATUS.COMPLETED) {
				return true
			}
			return false
		},
		isSowButtonUpdate() {
			if (this.isOrderComplete || this.orderForm.status?.value === ORDER_STATUS.CANCELLED) {
				return false
			}
			return true
		},
		isShowCancelButton() {
			if (IGNORE_ORDER_STATUS.includes(this.orderForm.status?.value)) {
				return false
			}
			return true
		},
		checkRequireThumbnail() {
			if (this.isCompleteUpdate && this.thumbnailSelected) {
				return false
			}
			return true
		},
		isRevision() {
			if (this.formDetail?.isRevision || this.orderDetail?.originalOrderId) return true
			return false
		},
	},
	watch: {
		formDetail: {
			handler() {
				this.setupData()
			},
			deep: true,
		},
		orderDetail: {
			handler() {
				this.setupData()
			},
			deep: true,
		},
	},
	mounted() {
		this.setupData()
	},
	methods: {
		...customerActions({ SEARCH_EMAIL }),
		...mapActions({ TRANSLATE_TEXT, CANCEL_ORDER_WEB }),
		setupData() {
			if (this.isRedirectForm && this.formDetail) {
				this.orderForm.receivedDate = this.formDetail.received
				this.orderForm.formID = this.formDetail.displayId
				if (!this.isUpdateOrder) {
					this.orderForm.status = {
						name: this.$t("OrderFilterName.new_job"),
						value: ORDER_STATUS.NEW_JOB,
					}
				} else {
					this.orderForm.status = {
						name: this.$t("OrderFilterName.revision"),
						value: ORDER_STATUS.REVISION,
					}
					this.listStatus = this.listStatus.filter(
						status =>
							status.value === ORDER_STATUS.REVISION || status.value === ORDER_STATUS.MORE_NEW_JOB,
					)
				}
				this.orderForm.customerEmail = this.formDetail.email

				if (this.formDetail.phoneNumber) {
					this.orderForm.phoneNumber = `+${this.formDetail.phoneCode}${this.formDetail.phoneNumber}`
				}

				this.orderForm.customerName = this.formDetail.customerName
				this.orderForm.customerId = this.formDetail.customerId
				this.orderForm.title = this.formDetail.title
				this.orderForm.description = this.formDetail.description
				this.orderForm.orderType = this.formDetail.orderTypes[0].id
				this.orderTypeSelected = this.formDetail.orderTypes.map(el => {
					return {
						...el,
						amount: this.formDetail.amount,
					}
				})
				if (this.formDetail.driveSharedFolderLinks) {
					this.orderForm.driveSharedFolderLinks = this.formDetail.driveSharedFolderLinks
				}
				if (this.formDetail.dropboxSharedFolderLinks) {
					this.orderForm.dropboxSharedFolderLinks = this.formDetail.dropboxSharedFolderLinks
				}
				if (this.formDetail.adminDropboxFolderLinks) {
					this.orderForm.adminDropboxFolderLinks = this.formDetail.adminDropboxFolderLinks
				}
				this.uploadFiles = this.formDetail.attachments || []

				if (this.$route.query.update_order) {
					if (this.formDetail.requestPrice) {
						this.orderForm.cost = this.formatPricePackage(this.formDetail.requestPrice)
					} else {
						this.orderForm.cost = 0
					}
				} else {
					this.orderForm.cost = this.formatPricePackage(this.formDetail.finalPrice)
				}
				this.orderForm.time = this.formDetail.packageDeliveryTime * 24
				this.setTimeDeadline()
				this.searchCustomers()
			}

			if ((this.isView || this.isUpdate) && this.orderDetail) {
				this.orderForm = { ...this.orderForm, ...this.orderDetail }
				this.orderForm.formID = this.orderForm.fromDisplayId
				this.orderForm.updateID = this.orderForm.id

				this.orderForm.receivedDate = this.convertUTCtoDateVN(this.orderForm.receivedDate)
				this.orderForm.deadLine = this.convertUTCtoDateVN(this.orderForm.deadLine)
				this.createdDate = this.convertUTCtoDateVN(this.orderForm.createdAt)

				if (this.orderForm.phoneNumber) {
					this.orderForm.phoneNumber = `+${this.orderForm.phoneCode}${this.orderForm.phoneNumber}`
				}

				if (!this.orderForm.driveSharedFolderLinks) {
					this.orderForm.driveSharedFolderLinks = []
				}
				if (!this.orderForm.dropboxSharedFolderLinks) {
					this.orderForm.dropboxSharedFolderLinks = []
				}
				if (!this.orderForm.adminDropboxFolderLinks) {
					this.orderForm.adminDropboxFolderLinks = []
				}

				this.orderForm.status = this.listStatus.find(
					status => status.value === this.orderForm.status,
				)

				this.orderForm.cost = this.formatPricePackage(this.orderForm.cost) || 0

				this.resultFiles = this.orderForm.resultFiles

				this.orderForm.orderType = this.orderForm.orderTypes[0].id
				this.orderTypeSelected = this.orderForm.orderTypes
				this.orderForm.time = this.orderForm.duration * 24

				this.uploadFiles = this.orderForm.uploadFiles
			}
		},
		submitForm() {
			let params = {}

			if (this.isUpdate) {
				params = {
					orderId: this.id,
					isComplete: this.orderForm.isComplete,
					shareFiles: this.orderForm.shareFiles,
					rule: this.orderForm.rule,
					translatedDescription: this.orderForm.translatedDescription,
				}

				const newResultFiles = [...this.resultFiles].filter(el => el.file)

				if (newResultFiles.length) {
					params["resultFiles"] = newResultFiles
				}

				const oldFileResult = this.resultFiles.filter(el => !el.file)

				if (oldFileResult.length) {
					params["resultFilePaths"] = oldFileResult.map(el => el.path)
				}

				if (this.thumbnailSelected) {
					if (this.thumbnailSelected.file) {
						params["thumbnail"] = this.thumbnailSelected
						params["thumbnailMimeType"] = this.thumbnailSelected.file.type
					} else {
						params["thumbnailResultPath"] = this.thumbnailSelected.path
						params["thumbnailMimeType"] = this.thumbnailSelected.mimeType
					}
				}
				if (this.orderForm.translatedDescription) {
					params["translatedDescription"] = this.orderForm.translatedDescription
				}
			} else {
				params = {
					status: this.orderForm.status.value,
					title: this.orderForm.title,
					description: this.orderForm.description,
					translatedDescription: this.orderForm.translatedDescription,
					duration: this.formDetail.packageDeliveryTime,
					deadLine: this.convertDateVnToISO(this.orderForm.deadLine),
					rule: this.orderForm.rule,
					receivedDate: this.convertDateVnToISO(this.orderForm.receivedDate),
					cost: this.orderForm.cost
						? Number(this.orderForm.cost.split(",").join(""))
						: this.orderForm.cost,
					price: this.orderForm.cost
						? Number(this.orderForm.cost.split(",").join(""))
						: this.orderForm.cost,
					shareFiles: this.orderForm.shareFiles,
					phoneCode: this.formDetail.phoneCode,
					phoneCountry: this.formDetail.phoneCountry,
					phoneNumber: this.formDetail.phoneNumber,
					formId: this.formId,
					orderTypesWithAmount: this.getOrderTypeWithAmount(),
				}

				if (this.orderForm.adminDropboxFolderLinks.length) {
					params["adminDropboxFolderLinks"] = this.orderForm.adminDropboxFolderLinks
				}
				if (this.orderForm.dropboxSharedFolderLinks.length) {
					params["dropboxSharedFolderLinks"] = this.orderForm.dropboxSharedFolderLinks
				}
				if (this.orderForm.driveSharedFolderLinks.length) {
					params["driveSharedFolderLinks"] = this.orderForm.driveSharedFolderLinks
				}
				if (this.uploadFiles.length) {
					params["attachmentPaths"] = this.uploadFiles.map(el => el.path)
				}
				if (this.isUpdateOrder) {
					params["originalOrderId"] = this.$route.query.original_order
				}
			}
			this.$emit("submit", params)
		},
		getOrderTypeWithAmount() {
			return {
				id: this.orderForm.orderType,
				amount: this.formDetail.amount,
			}
		},

		async searchCustomers() {
			if (!this.orderForm.customerEmail) return
			const params = {
				data: {
					search: this.orderForm.customerEmail,
				},
				notLoading: true,
			}
			const res = await this.SEARCH_EMAIL(params)
			if (res.length >= 1) {
				const customer = res[0]
				this.orderForm.rule = customer?.rule
			}
		},
		setTimeDeadline() {
			if (!this.orderForm.time) return null

			const hour = this.orderForm.time

			const received = moment(this.orderForm.receivedDate, "DD-MM-YYYY HH:mm").format()
			const newDate = new Date(received)
			newDate.setHours(newDate.getHours() + hour)
			this.orderForm.deadLine = moment(newDate).format("DD-MM-YYYY HH:mm")
		},
		async translateText() {
			if (!this.orderForm.description) return
			const params = {
				text: this.orderForm.description,
			}
			const res = await this.TRANSLATE_TEXT(params)
			if (res) {
				this.orderForm.translatedDescription = res.text
			}
		},
		back() {
			this.$emit("back")
		},
		async addLink() {
			const validate = await this.$refs.adminLink.validate()
			const value = this.$refs.adminLink.value

			if (!validate.valid || !value) {
				return
			}

			this.orderForm.adminDropboxFolderLinks.unshift(this.orderForm.adminLink)
			this.orderForm.adminLink = null
		},
		removeLink(index) {
			this.orderForm.adminDropboxFolderLinks.splice(index, 1)
		},
		showModalCancelOrder() {
			this.isCancelOrder = true
			this.$store.commit("set", ["modalShow", true])
		},
		async submitModal() {
			const valid = await this.$refs.formCancel.validate()
			if (!valid) return

			const params = {
				orderId: this.id,
				cancelReason: this.reason,
			}
			const res = await this.CANCEL_ORDER_WEB(params)
			if (res) {
				this.$emit("cancel-order")
				this.cancelModal()
			}
		},
		cancelModal() {
			this.reason = null
			this.isCancelOrder = false
			this.$store.commit("set", ["modalShow", false])
		},
		updateOreder() {
			this.$emit("update")
		},
		chooseThumbnail(thumbnail) {
			this.thumbnailSelected = thumbnail
			this.$refs.thumbnail.reset()
		},
		getTotalFiles(total) {
			this.totalFiles = total
		},
		async handleUploadResultFiles(files) {
			this.isUploadSuccess = false
			const newFiles = [...this.resultFiles, ...files]
			const result = await this.getFileNameDuplicate(newFiles)
			if (result.length) {
				const fileName = result.map(el => el.name)
				this.messageDuplicate = `${this.$t("messages.duplicate.left")} ${join(
					fileName,
					", ",
				)} ${this.$t("messages.duplicate.right")}`
				this.isDuplicateFile = true
				this.$store.commit("set", ["modalShow", true])
			}
			this.resultFiles = newFiles
			if (this.resultFiles.length === this.totalFiles) this.isUploadSuccess = true
			// this.resultFiles = [...this.resultFiles, ...files]
		},
		handleDeleteResultFile(index) {
			this.resultFiles.splice(index, 1)
		},
		confirmDuplicate() {
			this.resultFiles = uniqBy(this.resultFiles, "name")
			this.isDuplicateFile = false
			this.$store.commit("set", ["modalShow", false])
		},
		changeIsCompleteUpdate(value) {
			this.isCompleteUpdate = value
			if (value) {
				this.orderForm.isComplete = 1
			} else {
				this.orderForm.isComplete = 0
			}
		},
		formatPricePackage(price) {
			if (!price) return price

			let priceFormat = null

			if (isInteger(price)) {
				priceFormat = this.formatNumber(round(price, 2).toString())
			} else {
				let decimal_pos = price.toString().split(".")

				let left_side = decimal_pos[0]
				let right_side = decimal_pos[1]

				left_side = this.formatNumber(left_side)
				right_side = right_side?.substring(0, 2)
				priceFormat = left_side + "." + right_side
			}
			return priceFormat
		},
		formatNumber(n) {
			if (!n) return null
			// format number 1000000 to 1,234,567
			return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.custom__input {
	width: 100%;
}
.form__input {
	padding: 0.375rem 0.75rem;
	height: 35px;
}
.dropbox {
	&-item {
		padding-left: 0.75rem;
		border: 1px solid $border-input;
		border-radius: 0.25rem;
		border-right: unset;
		min-height: 35px;
	}
}
.dropbox-url {
	width: calc(100% - 60px);
}

.btn-delete {
	min-width: 40px;
	height: 35px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	svg {
		font-size: 18px;
	}
}
.disabled-link {
	background: $grey-5;
	cursor: not-allowed;
}
.icon {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: 10;
	min-width: 40px;
	height: 35px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	svg {
		font-size: 18px;
	}
}
.thumbnail {
	width: 117px;
	height: 123px;
	opacity: 1;
	transition: all 0.7s ease-out;
	position: relative;

	> img {
		width: 100%;
		height: 100%;
		border-radius: 8px;
		object-fit: cover;
		cursor: pointer;
		border: 1px solid $border-input;
	}

	&-blank {
		width: 100%;
		height: 100%;
		background: #f3f3f3;
		border-radius: 8px;
		border: 1px solid $border-input;
	}
}

.error-mess {
	color: $red;
}
</style>
